import React, { useState } from "react"
import Navitem from "./navitem"
import window from "global"
import { Link } from "gatsby"
import { Container, Collapse, Navbar, Nav } from "reactstrap"
import { FiPhone } from "react-icons/fi"
import { MdMail } from "react-icons/md"
import { GrInstagram } from "react-icons/gr"
import { FaFacebookF } from "react-icons/fa"
import { TiThLarge } from "react-icons/ti"

const MyNav = () => {
  //Nacbar Toggler
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  //Nacbar Color on Scroll
  window.onscroll = () => {
    const scrollNav = document.querySelector("nav")
    const scrollMe = window.scrollY
    if (scrollMe >= 40) {
      scrollNav.classList.add("scroll")
    } else {
      scrollNav.classList.remove("scroll")
    }
  }

  return (
    <Navbar className="navc fixed-top" expand="lg">
      <Container>
        <Link className="g-1" to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="30"
            viewBox="0 0 24 34"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.373268 3.82523C-0.169195 9.12171 -0.169195 10.1025 0.698746 11.0834C1.45819 11.9661 1.45819 14.7124 0.481761 29.1306C0.264775 32.4654 1.56669 34.6233 3.19408 33.8386C4.49599 33.2501 4.92996 31.6808 4.71297 27.9536L4.49599 24.4226H10.3546V26.1881C10.3546 32.7597 15.8877 34.5252 21.4208 29.621C25.0011 26.4824 24.7841 24.6188 21.2038 27.2671C14.2603 32.4654 12.0905 28.346 15.1283 15.8894C16.4302 10.691 16.3217 9.61213 14.5858 9.61213C13.5009 9.61213 10.6801 17.2626 10.6801 20.107V21.0878H4.49599L4.279 20.0089C3.73654 17.753 4.06202 12.0642 4.60448 11.1815C5.25544 10.2006 5.36393 9.31788 4.92996 3.82523C4.60448 -0.686581 3.95352 -1.27508 4.06202 3.04057C4.17051 5.49264 4.06202 6.27731 3.73654 6.27731C3.41106 6.27731 3.30257 5.49264 3.30257 3.13865V0H2.10915L0.807238 0.0980829L0.373268 3.82523ZM1.78367 4.963C1.78367 5.62015 1.56669 6.27731 1.24121 6.27731C0.698746 6.27731 0.481761 4.08025 0.915731 3.20731C1.3497 2.33437 1.78367 3.42309 1.78367 4.963Z"
              fill="black"
            />
          </svg>
        </Link>
        <button
          aria-label="Togglenavigation"
          type="button"
          className="navbar-toggler"
          onClick={toggle}
        >
          <TiThLarge size={25} />
        </button>
        <Collapse className="g-2" isOpen={isOpen} navbar>
          <Nav navbar className="float-right">
            <Navitem name="About" url="/about" />
            <Navitem name="Service" url="/service" />
            <Navitem name="Contact" url="/contact" />
          </Nav>
        </Collapse>
        <div className="g-3">
          <div className="float-right">
            <div className="make-reach">
              <span className="reach">
                <a className="reach-p" href="tel: 562-684-2514">
                  <FiPhone size={15} />
                </a>
              </span>
              <span className="reach">
                <a
                  className="reach-e"
                  href="mailto:office@hungryhipposolutions.com"
                >
                  <MdMail size={17} />
                </a>
              </span>
              <span className="reach">
                <a
                  className="reach-i"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/imandiaz/"
                >
                  <GrInstagram size={14} />
                </a>
              </span>
              <span className="reach">
                <a
                  className="reach-i"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://web.facebook.com/hungryhipposolutions"
                >
                  <FaFacebookF size={13} />
                </a>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  )
}

export default MyNav

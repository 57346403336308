import React, { Fragment } from "react"
import PropTypes from "prop-types"
import MyNav from "./navbar/navbar"
import Footer from "./footer/footer"

const Layout = ({ children }) => {
  return (
    <Fragment>
      <MyNav />
      {children}
      <Footer />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React, { Fragment, useState } from "react"
import { Container, Row } from "reactstrap"
import { GrInstagram } from "react-icons/gr"
import { FaFacebookF } from "react-icons/fa"
import { AiOutlineYoutube } from "react-icons/ai"
import axios from "axios"
import { Link } from "gatsby"

const Footer = () => {
  //email hook
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = evt => {
    evt.preventDefault()

    //start spining
    document.querySelector(".my-spiner").classList.add("show")

    //remove button
    document.querySelector(".subs-button").classList.add("hide")

    //Api call to sendfox
    try {
      const makePost = async () => {
        await axios({
          method: "post",
          url: "https://hook.integromat.com/7z2v1spqqqbi2fxb3hvob1qgnfmf3lkb",
          data: {
            email: `${email}`,
          },
        })
        //make success
        setMessage("You are successfully submitted the form.")
        setEmail("")

        //remove spining
        document.querySelector(".my-spiner").classList.remove("show")
      }

      //run function
      makePost()
    } catch (error) {
      console.error("Error:", error)
      setMessage("Error")
    }
  }

  return (
    <Fragment>
      <footer>
        <Container>
          <Row className="sm-pad">
            <div className="col-md-4 footer-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="30"
                viewBox="0 0 24 34"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.373268 3.82523C-0.169195 9.12171 -0.169195 10.1025 0.698746 11.0834C1.45819 11.9661 1.45819 14.7124 0.481761 29.1306C0.264775 32.4654 1.56669 34.6233 3.19408 33.8386C4.49599 33.2501 4.92996 31.6808 4.71297 27.9536L4.49599 24.4226H10.3546V26.1881C10.3546 32.7597 15.8877 34.5252 21.4208 29.621C25.0011 26.4824 24.7841 24.6188 21.2038 27.2671C14.2603 32.4654 12.0905 28.346 15.1283 15.8894C16.4302 10.691 16.3217 9.61213 14.5858 9.61213C13.5009 9.61213 10.6801 17.2626 10.6801 20.107V21.0878H4.49599L4.279 20.0089C3.73654 17.753 4.06202 12.0642 4.60448 11.1815C5.25544 10.2006 5.36393 9.31788 4.92996 3.82523C4.60448 -0.686581 3.95352 -1.27508 4.06202 3.04057C4.17051 5.49264 4.06202 6.27731 3.73654 6.27731C3.41106 6.27731 3.30257 5.49264 3.30257 3.13865V0H2.10915L0.807238 0.0980829L0.373268 3.82523ZM1.78367 4.963C1.78367 5.62015 1.56669 6.27731 1.24121 6.27731C0.698746 6.27731 0.481761 4.08025 0.915731 3.20731C1.3497 2.33437 1.78367 3.42309 1.78367 4.963Z"
                  fill="white"
                />
              </svg>
              <br />
              <span>Hungry Hippo Solutions</span>
              <p>201 E Center St Ste 112 Anaheim, CA 92805</p>
              <div className="contact-list">
                <b>Email</b> :{" "}
                <a href="mailto:office@hungryhipposolutions.com">
                  office@hungryhipposolutions.com
                </a>
                <br />
                <b>Phone</b> : <a href="tel: 562-684-2514"> 562-684-2514</a>
              </div>
            </div>
            <div className="col-md-4 footer-center">
              We are a digital agency focused on giving restaurants the systems,
              software, and support to grow their takeout and deliveries. There
              is no need to pay commissions on your takeout and delivery orders.
              <br />
              <br />
              <div className="footer-link">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/imandiaz/"
                >
                  <GrInstagram size={14} />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://web.facebook.com/hungryhipposolutions"
                >
                  <FaFacebookF size={14} />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCdAJQr4DMkyAmVcNk0VQoiA"
                >
                  <AiOutlineYoutube size={18} />
                </a>
              </div>
            </div>
            <div className="col-md-4 footer-right">
              <span>Need to grow your takeout and delivery orders?</span>
              <p>
                Join our newsletter to see how we help restaurants continually
                improve their online takeout and deliver systems.
              </p>
              <form onSubmit={handleSubmit} className="subscribe">
                <div className="input-group">
                  <input
                    className="btn btn-sm"
                    name="email"
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <button className="btn btn-sm subs-button" type="submit">
                    Subscribe
                  </button>
                  <div className="my-spiner">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>

                  {message}
                </div>
              </form>
            </div>
          </Row>
        </Container>
      </footer>
      <div className="text-center copy">
        Copyright © 2022, All Rights Reserved by Hungry Hippo Solutions. |{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
        <Link to="/terms-conditions">Terms & Conditions </Link>|{" "}
        <Link to="/refund-policy">Refund Policy </Link>|{" "}
        <Link to="/cancellation-policy">Cancellation Policy</Link>
        <p>Hungry Hippo Solutions brought to you by Magi Media Group.</p>
      </div>
    </Fragment>
  )
}

export default Footer
